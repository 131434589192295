/* PROJECT SPECIFIC STYLES */
.megamenu__item-link-container[data-id="114307"] {
    .megamenu__item-link-content {
        color: #68BBA9 !important;
        position: relative;
        padding-left: 36px;

        @screen xl {
            color: #CBEBE4 !important;
        }

        &:before {
            width: 26px;
            height: 26px;
            background: #778386 url('../public/img/discount.svg') 50% 50% no-repeat;
            border-radius: 50%;
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}


/* Custom animation */
@keyframes bgErrorAnimation {
    0% {
        background-color: #e11a351a;
    }

    50% {
        background-color: #FFFFFF;
    }

    100% {
        background-color: #e11a351a;
    }
}

.backgroundErrorAnimation {
    animation: bgErrorAnimation 1s linear 0s 3;
}